<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-primary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'home'" class="font-primary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Total Property</span>
                            <h3 class="mb-0"><span class="counter">100</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden  widget-cards">
                <div class="bg-warning card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'user'" class="font-warning"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Total Owners</span>
                            <h3 class="mb-0"><span class="counter">50</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-secondary card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'calendar'" class="font-secondary"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Total Bookings</span>
                            <h3 class="mb-0"><span class="counter">1000</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card o-hidden widget-cards">
                <div class="bg-danger card-body">
                    <div class="media static-top-widget row">
                        <div class="icons-widgets col-3">
                            <div class="align-self-center text-center">
                                <app-feather-icons [icon]="'dollar-sign'" class="font-danger"></app-feather-icons>
                            </div>
                        </div>
                        <div class="media-body col-9"><span class="m-0">Net Profit</span>
                            <h3 class="mb-0">RM <span class="counter">45,631</span></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Top Investors -->
        <div class="col-xl-4 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Top Investors</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Name </th>
                                    <th scope="col">Revenue (RM)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="investor/investor-list" class="btn btn-primary ms-0">View All</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Top Category -->
        <div class="col-xl-4 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Top Category</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Name </th>
                                    <th scope="col">Revenue (RM)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="investor/investor-list" class="btn btn-primary ms-0">View All</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- Top Property -->
        <div class="col-xl-4 xl-100">
            <div class="card height-equal">
                <div class="card-header">
                    <h5>Top Property</h5>
                </div>
                <div class="card-body">
                    <div class="user-status table-responsive latest-order-table">
                        <table class="table table-bordernone">
                            <thead>
                                <tr>
                                    <th scope="col">Name </th>
                                    <th scope="col">Revenue (RM)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                                <tr>
                                    <td>Investor 1</td>
                                    <td class="digits">120.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="investor/investor-list" class="btn btn-primary ms-0">View All</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Total Sales</h6>
                    <div class="row">
                        <div class="col-6">
                            <div class="small-chartjs">
                                <canvas baseChart id="small-graph-1" height="600" width="400"
                                    [datasets]="smallLineChartData" [labels]="smallLineChartLabels"
                                    [options]="smallLineChartOptions" 
                                    [legend]="smallLineChartLegend" [type]="smallLineChartType"
                                    (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3>42% <span><i class="fa fa-angle-up font-primary"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Sales Last Month</span>
                            <h2 class="mb-0">9054</h2>
                            <p>0.25% <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600">Gross sales of August</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                        </div>
                        <div class="bg-primary b-r-8">
                            <div class="small-box">
                                <i data-feather="briefcase"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Total purchase</h6>
                    <div class="row">
                        <div class="col-6">
                            <div class="small-chartjs">

                                <canvas baseChart id="small-graph-2" height="60vw" width="140vw"
                                    [datasets]="smallLine2ChartData" [labels]="smallLine2ChartLabels"
                                    [options]="smallLine2ChartOptions" 
                                    [legend]="smallLine2ChartLegend" [type]="smallLine2ChartType"
                                    (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3>20% <span><i class="fa fa-angle-up font-secondary"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Monthly purchase</span>
                            <h2 class="mb-0">2154</h2>
                            <p>0.13% <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600">Avg Gross purchase</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                        </div>
                        <div class="bg-secondary b-r-8">
                            <div class="small-box">
                                <i data-feather="credit-card"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Total cash transaction</h6>
                    <div class="row">
                        <div class="col-6">
                            <div class="small-chartjs">
                                <canvas baseChart id="small-graph-3" height="60vw" width="140vw"
                                    [datasets]="smallLine3ChartData" [labels]="smallLine3ChartLabels"
                                    [options]="smallLine3ChartOptions" 
                                    [legend]="smallLine3ChartLegend" [type]="smallLine3ChartType"
                                    (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3>28% <span><i class="fa fa-angle-up font-warning"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Cash on hand</span>
                            <h2 class="mb-0">4672</h2>
                            <p>0.8% <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600">Details about cash</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                        </div>
                        <div class="bg-warning b-r-8">
                            <div class="small-box">
                                <i data-feather="shopping-cart"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-3 col-md-6 xl-50">
            <div class="card order-graph sales-carousel">
                <div class="card-header">
                    <h6>Daily Deposits</h6>
                    <div class="row">
                        <div class="col-6">
                            <div class="small-chartjs">
                                <canvas baseChart id="small-graph-4" height="60vw" width="140vw"
                                    [datasets]="smallLine4ChartData" [labels]="smallLine4ChartLabels"
                                    [options]="smallLine4ChartOptions"
                                    [legend]="smallLine4ChartLegend" [type]="smallLine4ChartType"
                                    (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)"></canvas>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="value-graph">
                                <h3>75% <span><i class="fa fa-angle-up font-danger"></i></span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="d-flex align-items-start">
                        <div class="media-body">
                            <span>Security Deposits</span>
                            <h2 class="mb-0">0782</h2>
                            <p>0.25% <span><i class="fa fa-angle-up"></i></span></p>
                            <h5 class="f-w-600">Gross sales of June</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting</p>
                        </div>
                        <div class="bg-danger b-r-8">
                            <div class="small-box">
                                <i data-feather="calendar"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Buy / Sell</h5>
                </div>
                <div class="card-body sell-graph">
                    <canvas baseChart id="myGraph" [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [legend]="lineChartLegend"
                        [type]="lineChartType" (chartHover)="chartHovered($event)"
                        (chartClick)="chartClicked($event)"></canvas>
                </div>
            </div>
        </div>
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Sales Status</h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph overflow-hidden">
                                <h6>Orders By Location</h6>
                                <div class="chart-block chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="pieData" [explodeSlices]="false"
                                        [labels]="doughnutChartShowLabels" [arcWidth]=0.50 [doughnut]="true"
                                        [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom">
                                    <div class="media">
                                        <div class="order-color-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Saint Lucia <span class="pull-right">$157</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Kenya <span class="pull-right">$347</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-danger"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Liberia<span class="pull-right">$468</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-warning"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Christmas Island<span class="pull-right">$742</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-color-success"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0">Saint Helena <span class="pull-right">$647</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 xl-50">
                            <div class="order-graph sm-order-space overflow-hidden">
                                <h6>Sales By Location</h6>
                                <div class="peity-chart-dashboard text-center chart-vertical-center">
                                    <ngx-charts-pie-chart class="pie-colours-1" [view]="view"
                                        [scheme]="doughnutChartColorScheme" [results]="doughnutData"
                                        [explodeSlices]="false" [labels]="doughnutChartShowLabels" [arcWidth]=0.99
                                        [doughnut]="true" [animations]="false" [tooltipDisabled]="doughnutChartTooltip"
                                        [gradient]="doughnutChartGradient">
                                    </ngx-charts-pie-chart>
                                </div>
                                <div class="order-graph-bottom sales-location">
                                    <div class="media">
                                        <div class="order-shape-primary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Germany <span class="pull-right">25%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-secondary"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Brasil <span class="pull-right">10%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-danger"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">United Kingdom<span class="pull-right">34%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-warning"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Australia<span class="pull-right">5%</span></h6>
                                        </div>
                                    </div>
                                    <div class="media">
                                        <div class="order-shape-success"></div>
                                        <div class="media-body">
                                            <h6 class="mb-0 me-0">Canada <span class="pull-right">25%</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 xl-100">
                            <div class="order-graph xl-space">
                                <h6>Revenue for last month</h6>
                                <div class="ct-4 flot-chart-container">
                                    <x-chartist [configuration]="chart5">
                                    </x-chartist>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Container-fluid Ends-->